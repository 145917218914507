import { Container, Grid } from "@mui/material"
import { useContext, useEffect, useState } from "react"
import { PreferenceCenterContext } from "../../contexts/PreferenceCenter"

export function getLocalized(object?: any): string | undefined {
    if (object) {
        const languages: string[] = JSON.parse(JSON.stringify(navigator.languages))
        languages.push('en')

        for (const language of languages) {
            if (object[language]) {
                return object[language]
            }
        }
    }
    return undefined
}

export function PartnerLogo() {
    const context = useContext(PreferenceCenterContext)
    const [insertLogo, setInsertLogo] = useState<string>()
    const [referralUrl, setReferralUrl] = useState<URL>()

    function getResellerBrand() {
        function addUtmParameters(url: URL): URL {
            if (context?.list && context?.partnerUrl) {
                url.searchParams.append('utm_medium', 'preference_center')
                url.searchParams.append('utm_content', 'partner_logo')
                url.searchParams.append('utm_campaign', `list_${context.list.id}`)
                url.searchParams.append('utm_source', context?.brand?.data?.client_config?.brand_repo?.brandId || context.partnerUrl.hostname)
            }

            return url
        }

        if (context?.partnerBrand && context?.brand) {
            const logoObject = context.partnerBrand.data?.images?.injected_logo
            //legacy images are key / value urls. Brand Repo has an object has a value
            const logo = logoObject?.url || logoObject
            const referral_badge = context.partnerBrand.data?.url?.referral_badge

            if (logo && context.brand.data?.config?.usage_limits?.insert_reseller_logo) setInsertLogo(logo)
            if (context.list) {
                const url = getLocalized(referral_badge)
                if (url) setReferralUrl(addUtmParameters(new URL(url)))
            }
        }
    }


    useEffect(getResellerBrand, [context?.partnerBrand, context?.list, context?.partnerUrl, context?.brand])

    return <>
        {!!insertLogo &&
            <Container sx={{ marginTop: 6 }}>
                <Grid container>
                    <Grid item sx={{ width: '100%', display: 'flex', justifyContent: 'center' }} justifyContent='center'>
                        {!referralUrl &&
                            <img src={insertLogo} alt='Reseller Logo' />
                        }
                        {referralUrl &&
                            <a href={referralUrl.href}>
                                <img src={insertLogo} alt='Reseller Logo' />
                            </a>
                        }
                    </Grid>
                </Grid>
            </Container>
        }
    </>
}
