import posthog from "posthog-js"
import { IAccount, IContact, IList, IPosthogConfig } from "../interfaces/IPosthogConfig"

export enum SEGMENT_EVENTS {
    IDENTIFY = "Identify",

    CONTACT_UPDATED = "Contact.Updated",
    CONTACT_RESUBSCRIBED = "Contact.Resubscribed",
    CONTACT_UNSUBSCRIBED = "Contact.Unsubscribed",

    BUTTON_CLICKED = "Button.Clicked",
    LINK_CLICKED = "Link.Clicked",
    DROPDOWN_CLICKED = "Dropdown.Clicked",
    DROPDOWN_OPTION_SELECTED = "Dropdown.Option.Clicked",
}

export function initPostHog(config: IPosthogConfig, id: string) {
    if (
        config.phId &&
        config.enabled &&
        !posthog.__loaded
    ) {
        posthog.init(config.phId, {
            api_host: config?.phServer || String(process.env.POSTHOG_SERVER),
            loaded: () => { },
            opt_in_site_apps: true,
            bootstrap: {
                distinctID: id.toLowerCase()
            },
        })
    }

}

export function identify(id: string, data: any = {}) {
    if (posthog.__loaded) {
        posthog.identify(
            cleanPostHogId(id),
            segmentPayloads(SEGMENT_EVENTS.IDENTIFY, data)
        )
    }
}

export function trackEvent(event: SEGMENT_EVENTS, data: any = {}) {
    if (posthog.__loaded) {
        posthog.capture(event, segmentPayloads(event, data))
    }
}

const segmentPayloads = (event: string, data: any = {}) => {
    let payload: any = {}

    switch (event) {
        case SEGMENT_EVENTS.IDENTIFY:
        case SEGMENT_EVENTS.CONTACT_UNSUBSCRIBED:
        case SEGMENT_EVENTS.CONTACT_RESUBSCRIBED:
            payload = identification(data)
            break

        case SEGMENT_EVENTS.CONTACT_UPDATED:
            payload = contact(data)
            break
        case SEGMENT_EVENTS.BUTTON_CLICKED:
        case SEGMENT_EVENTS.LINK_CLICKED:
        case SEGMENT_EVENTS.DROPDOWN_CLICKED:
        case SEGMENT_EVENTS.DROPDOWN_OPTION_SELECTED:
            payload = userAction(data)
            break
        default:
            payload = data ? data : {}
            break
    }

    payload.event_source = "preference center"

    return flattenObject(payload)
}

function flattenObject(obj: any, parentKey: string = '', result: any = {}) {
    for (let key in obj) {
        if (obj.hasOwnProperty(key)) {
            let newKey = parentKey ? `${parentKey}_${key}` : key

            if (typeof obj[key] === 'object' && !Array.isArray(obj[key])) {
                flattenObject(obj[key], newKey, result)
            } else {
                result[newKey] = obj[key]
            }
        }
    }
    return result
}

export function cleanPostHogId(id: string) {
    let formatedId = id || ""
    return formatedId.toLowerCase()
}


function identification(data: { list: any, account: any, contact: any }) {
    return {
        list: list(data.list),
        account: account(data.account),
        contact: contact(data.contact)
    }
}
function userAction(data: any) {
    return {
        context: data?.context,
        extended_context: data?.extended_context,
    }
}

function account(data: IAccount) {
    return {
        id: data?.id,
        lineage: data?.lineage,
        name: data?.name
    }
}

function list(data: IList) {
    return {
        id: data?.id,
    }
}

function contact(data: IContact) {
    return {
        custom_attributes: data?.customAttributes,
        email: data?.email,
        id: data?.id,
        initial_interests: data?.initialInterests,
        interests: data?.interests,
        status: data?.status
    }
}